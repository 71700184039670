import React, { useContext, useEffect } from "react";
import PageLayout from '../../components/PageLayout';
import { GlobalDispatchContext } from "./../../context/GlobalContextProvider"
import { ARTIST_LAUYOUT } from "./../../context/consts";
import GridLayout from './../../components/GridLayout';
import GridHoverableImageElement from "../../components/GridHoverableImageElement";
import { StaticImage } from "gatsby-plugin-image";
import Seo from "../../components/Seo";


const DotworksPage = () => {

    const dispatch = useContext(GlobalDispatchContext);
    useEffect(() => dispatch({ type: "TOGGLE_THEME", theme: ARTIST_LAUYOUT }), [dispatch])

    const imageComponents = [
        <GridHoverableImageElement key="0" year="2021" titleTop="Solaris">
            <StaticImage src="./../../images/artist/dotworks/01_2021_Dotwork_Solaris.jpg" alt="" layout="fullWidth" /></GridHoverableImageElement>,
        <GridHoverableImageElement key="1" year="2021" titleTop="Ilmonferrato">
            <StaticImage src="./../../images/artist/dotworks/02_2021_Dotwork_IlMonferrato.jpg" alt="" layout="fullWidth" /></GridHoverableImageElement>,
        <GridHoverableImageElement key="2" year="2020" titleTop="Melancolia" titleBottom="n.two">
            <StaticImage src="./../../images/artist/dotworks/03_2020_Dotwork_Melancolia2.jpg" alt="" layout="fullWidth" /></GridHoverableImageElement>,
        <GridHoverableImageElement key="3" year="2020" titleTop="Strong" titleBottom="Headache">
            <StaticImage src="./../../images/artist/dotworks/04_2020_Dotwork_StrongHeadache.jpg" alt="" layout="fullWidth" /></GridHoverableImageElement>,
        <GridHoverableImageElement key="4" year="2020" titleTop="Toseeher" titleBottom="isapicture">
            <StaticImage src="./../../images/artist/dotworks/05_2020_Dotwork_Malamenti.jpg" alt="" layout="fullWidth" /></GridHoverableImageElement>,
        <GridHoverableImageElement key="5" year="2020" titleTop="lionfloyd">
            <StaticImage src="./../../images/artist/dotworks/06_2020_Dotwork_LionFloyd.jpg" alt="" layout="fullWidth" /></GridHoverableImageElement>,
        <GridHoverableImageElement key="6" year="2020" titleTop="Ancient" titleBottom="oftheroad">
            <StaticImage src="./../../images/artist/dotworks/07_2020_Dotwork_AncientGodoftheRoad.jpg" alt="" layout="fullWidth" /></GridHoverableImageElement>,
        <GridHoverableImageElement key="7" year="2019" titleTop="Octo" titleBottom="Alchemy">
            <StaticImage src="./../../images/artist/dotworks/08_2019_Dotwork_OctoAlchemy.jpg" alt="" layout="fullWidth" /></GridHoverableImageElement>,
        <GridHoverableImageElement key="8" year="2019" titleTop="lordof" titleBottom="theflood">
            <StaticImage src="./../../images/artist/dotworks/09_2019_Dotwork_LordOftheFlood.jpg" alt="" layout="fullWidth" /></GridHoverableImageElement>,
        <GridHoverableImageElement key="9" year="2019" titleTop="afoxasagift">
            <StaticImage src="./../../images/artist/dotworks/10_2019_Dotwork_AFoxasaGift.jpg" alt="" layout="fullWidth" /></GridHoverableImageElement>,
        <GridHoverableImageElement key="10" year="2018" titleTop="sabin's" titleBottom="rape2.0">
            <StaticImage src="./../../images/artist/dotworks/11_2018_Dotwork_Sabin'sRape.jpg" alt="" layout="fullWidth" /></GridHoverableImageElement>,
        <GridHoverableImageElement key="11" year="2018" titleTop="munin">
            <StaticImage src="./../../images/artist/dotworks/12_2018_Dotwork_Munin.jpg" alt="" layout="fullWidth" /></GridHoverableImageElement>,
        <GridHoverableImageElement key="12" year="2018" titleTop="ilquattro" titleBottom="didenari">
            <StaticImage src="./../../images/artist/dotworks/13_2018_Dotwork_4diDenari.jpg" alt="" layout="fullWidth" /></GridHoverableImageElement>,
        <GridHoverableImageElement key="13" year="2017" titleTop="diluvium">
            <StaticImage src="./../../images/artist/dotworks/14_2017_Dotwork_Diluvium.jpg" alt="" layout="fullWidth" /></GridHoverableImageElement>,
        <GridHoverableImageElement key="14" year="2016" titleTop="fragmented">
            <StaticImage src="./../../images/artist/dotworks/15_2016_Dotwork_Fragmented.jpg" alt="" layout="fullWidth" /></GridHoverableImageElement>,
        <GridHoverableImageElement key="15" year="2015" titleTop="galileo">
            <StaticImage src="./../../images/artist/dotworks/16_2015_Dotwork_Galileo.jpg" alt="" layout="fullWidth" /></GridHoverableImageElement>,
    ];

    return (
        <PageLayout secondHead="Dotworks" showBack={true} linkTo="/artist">
            <GridLayout components={imageComponents}></GridLayout>
            <Seo subTitle="dotworks" />
        </PageLayout >
    )
}

export default DotworksPage